'use client'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { Container } from '@/uikit/container'
import { Section } from '@uikit/section'

export default function Loading() {
  return (
    <Container variant="full">
      <Section>
        <Section.Title>
          <SkeletonTheme baseColor="#f2f2f2" highlightColor="#d9dadb">
            <Skeleton />
          </SkeletonTheme>
        </Section.Title>
        <Section.Grid column="three">
          <SkeletonTheme baseColor="#f2f2f2" highlightColor="#d9dadb">
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
          </SkeletonTheme>
        </Section.Grid>
      </Section>
    </Container>
  )
}
