import { withStyles } from '@bruitt/classnames'
import { FC, ReactNode } from 'react'

import styles from './section.module.scss'
import { Container } from '../container'

export interface SectionProps {
  children?: ReactNode
  isHavePadding?: boolean
}

const sx = withStyles(styles)

const Section: FC<SectionProps> = ({ children, isHavePadding = false }) => {
  return (
    <div className={sx('root', { isHavePadding })}>
      <Container>{children}</Container>
    </div>
  )
}

const SectionTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return <h2 className={styles.title}>{children}</h2>
}

SectionTitle.displayName = 'SectionTitle'

const SectionGrid: FC<{ children: ReactNode; column?: 'two' | 'three' }> = ({
  children,
  column = 'two',
}) => {
  return <div className={sx('grid', { column })}>{children}</div>
}

SectionGrid.displayName = 'SectionGrid'

const SectionNamespace = Object.assign(Section, {
  Title: SectionTitle,
  Grid: SectionGrid,
})

export { SectionNamespace as Section }
