import { withStyles } from '@bruitt/classnames'
import { FC, ReactNode } from 'react'

import styles from './container.module.scss'

export interface ContainerProps {
  variant?: 'full' | 'narrow' | 'wide'
  children: ReactNode
}

const sx = withStyles(styles)

export const Container: FC<ContainerProps> = ({ variant = 'wide', children }) => {
  return <div className={sx('root', { variant })}>{children}</div>
}
